import { useState, useEffect } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { useParams } from "react-router-dom";

import WrapperContainer from "./Components/Container/WrapperContainer";


const theme = createTheme({
  palette: {
    primary: {
      light: "#8d8e8d",
      main: "#14a510",
      dark: "#3f8146",
      contrastText: "#fff",
    },
  },
});

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

function App(props) {
  const [data, setData] = useState({
    candidate: {},
    loading: false,
    error: false,
  });

  const urlParams = useParams();

  const fetchCandidate = async () => {
    try {
      let response = await fetch(`https://perunhr-simple-api.onrender.com/referralInfo/${urlParams.id}`);
      let candidate = await response.json();
      document.title = candidate.candidate.name;
      return { success: true, candidate };
    } catch (e) {
      console.log(e);
      return { success: false };
    }
  };

  useEffect(() => {
    (async () => {
      //Izvrsava se kad se komponenta renderuje
      setData({ ...data, loading: true });
      let res = await fetchCandidate();
      if (res.success) {
        setData({ ...data, candidate: res.candidate, loading: false });
      } else {
        setData({ ...data, error: true });
      }
    })();
  }, []);

  return (
    <div
      className="App"
      style={{ backgroundColor: "lightgray", minHeight: "1000px" }}
    >
      <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
          {/* // APP BAR START */}
          <AppBar position="fixed" color="primary">
            <Toolbar>
              <img
                style={{
                  margin: "auto",
                  textAlign: "center",
                  maxWidth: "50%",
                  maxHeight: "70%",
                }}
                src="./logo.gif"
                width="15%"
                height="15%"
              />
            </Toolbar>
          </AppBar>
          {/* // APP BAR STOP */}
        </Box>
        <br />
        {/* //MAIN CONTAINER START */}
        <Container maxWidth="md" sx={{ marginTop: "80px"}}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper sx={{ marginBottom: "20px", minHeight: "400px"}}>
                  <WrapperContainer
                    data={data.candidate}
                    error={data.error}
                    loading={data.loading}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
        {/* //MAIN CONTAINER STOP */}
      </ThemeProvider>
    </div>
  );
}

export default App;
